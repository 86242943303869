<mat-toolbar color="primary" class="header" *ngIf="nav.visible">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
    *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span>{{isDevelopment() ? 'AGRIORDERS DEVELOPMENT' : 'AgriOrders'}}</span>
  <span class="spacer"></span>
  <!-- <button mat-icon-button>
    <mat-icon matBadge="3" matBadgeColor="warn">notifications</mat-icon>
  </button>     -->
  <button mat-icon-button [matMenuTriggerFor]="usermenu">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #usermenu="matMenu">
    <div mat-menu-item><b>{{user.fullName}}</b></div>
    <button mat-menu-item (click)="logOut()">Log Out</button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" [ngClass]="{'has-toolbar': nav.visible}">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" *ngIf="nav.visible">
    <mat-nav-list>
      <a mat-list-item [routerLink]="['ordersdash']" routerLinkActive="active">
        <mat-icon matListItemIcon>dashboard</mat-icon>
        <span class="sidenav-link">Order Dashboard</span>
      </a>
      <a mat-list-item [routerLink]="['orders/edit']" routerLinkActive="active">
        <mat-icon matListItemIcon>add_circle</mat-icon>
        <span class="sidenav-link">Create SO</span>
      </a>
      <a mat-list-item [routerLink]="['purchaseorders/edit']" routerLinkActive="active">
        <mat-icon matListItemIcon>add_circle</mat-icon>
        <span class="sidenav-link">Create PO</span>
      </a>
      <a mat-list-item [routerLink]="['orders']" routerLinkActive="active" class="sidenav-link"
        [routerLinkActiveOptions]="{exact: true}">
        <mat-icon matListItemIcon>list</mat-icon>
        <span class="sidenav-link">Orders List</span>
      </a>
      <a mat-list-item [routerLink]="['customers']" routerLinkActive="active">
        <mat-icon matListItemIcon>groups</mat-icon>
        <span class="sidenav-link">Customers</span>
      </a>
      <a mat-list-item [routerLink]="['productlist']" routerLinkActive="active"
        *ngIf="auth.userHasAnyRole(['Admin', 'Office-Admin'])">
        <mat-icon matListItemIcon>inventory</mat-icon>
        <span class="sidenav-link">Product List</span>
      </a>
      <a mat-list-item [routerLink]="['newproductlist']" routerLinkActive="active">
        <mat-icon matListItemIcon>inventory</mat-icon>
        <span class="sidenav-link">Product List 2.0</span>
      </a>
      <a mat-list-item [routerLink]="['flclist']" routerLinkActive="active">
        <mat-icon matListItemIcon>inventory</mat-icon>
        <span class="sidenav-link">FLC List</span>
      </a>
      <a mat-list-item [routerLink]="['compostlists']" routerLinkActive="active"
        *ngIf="auth.userHasAnyRole(['Admin', 'Office-Admin'])">
        <mat-icon matListItemIcon>inventory</mat-icon>
        <span class="sidenav-link">Compost Lists</span>
      </a>
      <a mat-list-item [routerLink]="['compostanalysis']" routerLinkActive="active">
        <mat-icon matListItemIcon>description</mat-icon>
        <span class="sidenav-link">Compost Analysis</span>
      </a>
      <a mat-list-item [routerLink]="['purchaseorderlists']" routerLinkActive="active"
        *ngIf="auth.userHasAnyRole(['Admin', 'Office-Admin'])">
        <mat-icon matListItemIcon>inventory</mat-icon>
        <span class="sidenav-link">Purchase Order Lists</span>
      </a>
      <a mat-list-item [routerLink]="['reports']" routerLinkActive="active">
        <mat-icon matListItemIcon>list_alt</mat-icon>
        <span class="sidenav-link">Order Reports</span>
      </a>
      <a mat-list-item [routerLink]="['users']" routerLinkActive="active" *ngIf="auth.userHasAnyRole(['Admin'])">
        <mat-icon matListItemIcon>group add</mat-icon>
        <span class="sidenav-link">Users</span>
      </a>
      <a mat-list-item [routerLink]="['scaletickets']" routerLinkActive="active"
        *ngIf="auth.userHasAnyRole(['Admin', 'Office-Admin'])">
        <mat-icon matListItemIcon>publish</mat-icon>
        <span class="sidenav-link">Scale Tickets</span>
      </a>
      <!-- <a mat-list-item [routerLink]="['imports']" routerLinkActive="active"
        *ngIf="auth.userHasAnyRole(['Admin', 'Office-Admin'])">
        <mat-icon matListItemIcon>publish</mat-icon>
        <span class="sidenav-link">Bulk Import</span>
      </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="layout-content">
    <router-outlet></router-outlet>
    <!-- <footer class="footer theme-bg-primary">
      <div>
        Created by SandboxSoftware
      </div>
    </footer> -->
  </mat-sidenav-content>

</mat-sidenav-container>